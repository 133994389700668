<template>
    <div>
        <!--begin::User-->
        <div class="card card-custom">
            <div class="card-header flex-wrap border-0 pt-6 pb-0">
                <div class="card-title m-0">
                    <h3 class="card-label m-0">
                        {{$t('insurance_agents.insurance_agents')}}
                        <span class="text-muted pt-2 font-size-sm d-block"></span>
                    </h3>
                </div>
                <div class="card-toolbar">
                    <!--begin::Button-->
<!--                    <router-link to="/insuranceagents/insurance-agents" class="btn btn-light font-weight-bolder">-->
<!--                        <v-icon>mdi-chevron-left</v-icon>-->
<!--                        {{$t('back')}}-->
<!--                    </router-link>-->
                    <!--end::Button-->
                </div>
            </div>

            <div class="card-body">
                <b-card-body class="p-0">
                    <div class="form-group row">
                       
                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents.name')}}<span class="text-danger">*</span></label>
                            <input type="text" v-model="data.name" class="form-control" :class="validation && validation.name ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.name" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.name[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents.telephone')}}</label>
                            <vue-tel-input v-model="data.telephone" :class="validation && validation.telephone ? 'is-invalid' : ''"></vue-tel-input>
                            <!-- <input type="text" v-model="data.telephone" class="form-control" :class="validation && validation.telephone ? 'is-invalid' : ''"/> -->
                            <span v-if="validation && validation.telephone" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.telephone[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents.email')}}</label>
                            <input type="email" v-model="data.email" class="form-control" :class="validation && validation.email ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.email" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.email[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents.website')}}</label>
                            <input type="text" v-model="data.website" class="form-control" :class="validation && validation.website ? 'is-invalid' : ''"/>
                            <span v-if="validation && validation.website" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.website[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>{{$t('insurance_agents.image')}}</label>
                            <upload-file
                                    @file="listenerAttachment"
                                    :inner-id="'image'"
                                    :placeholder="$t('insurance_agents.upload_image')"
                                    :upload="dir_upload"
                                    :start-link="'base'"
                                    v-bind:valueprop="{name:'attachment',path:img_url}" v-if="reloadUploadAttachment">
                            </upload-file>
                            <span v-if="validation && validation.attachment" class="fv-plugins-message-container invalid-feedback">
                                {{ validation.attachment[0] }}
                            </span>
                        </div>

                        <div class="col-lg-6 mb-5">
                            <label>&ensp;</label>
                            <b-form-checkbox size="lg" v-model="data.status" name="check-button" switch>{{$t('status')}}</b-form-checkbox>
                        </div>
                        
                    </div>
                </b-card-body>

                <div class="card-footer pl-0 pr-0">
                    <div class="row">
                        <div class="col-lg-6">
                            <button type="button" class="btn btn-primary mr-2" @click="save">{{ $t('save') }}</button>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!--end::User-->
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
    import ApiService from "@/core/services/api.service";

    export default {
        name: "from-insurance-agents",
        data() {
            return {
                mainRoute: 'insuranceagents/insurance-agents',
                mainRouteDependency: 'base/dependency',
                dir_upload: 'insurance_agents',


                idEdit: this.$route.params.id ? this.$route.params.id : null,
                data: {
                    email: null,
                    telephone: null,
                    website: null,
                    name: null,
                    img: null,
                    status: true,
                },
                isEditing: false,
                validation: null,
                img_url: null,
                reloadUploadAttachment: true,

            };
        },

        methods: {
            save() {
                if (this.isEditing) {
                    this.update();
                } else {
                    this.create();
                }
            },

            create() {
                ApiService.post(this.mainRoute, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/insuranceagents/insurance-agents');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            update() {
                ApiService.put(this.mainRoute + '/' + this.idEdit, {
                    ...this.data,
                }).then((response) => {
                    this.validation = null;
                    this.$successAlert(response.data.message);
                    this.$router.push('/insuranceagents/insurance-agents');
                }).catch((error) => {
                    this.$errorAlert(error);
                    this.validation = error.response ? error.response.data.errors : null;
                });
            },

            getData() {
                ApiService.get(this.mainRoute + '/' + this.idEdit).then((response) => {
                    this.isEditing = true;
                    this.data.name = response.data.data.name;
                    this.data.email = response.data.data.email;
                    this.data.telephone = response.data.data.telephone;
                    this.data.website = response.data.data.website;
                    this.data.img = response.data.data.img;
                    this.img_url = response.data.data.img_url;
                    this.data.status = response.data.data.status;
                    this.reloadUploadAttachment = true;

                });
            },

            loadOptions() {
            },
            updateValue(value) {
                this.data.parent_category = value
            },
            listenerAttachment(event) {
                if (event) {
                    this.img_url = event.pathDB;
                    this.data.img = event.name;
                }
            },

        },

        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [{title: this.$t("MENU.insurance_agents"), route:'/insuranceagents/insurance-agents'}, {title: (this.idEdit ? this.$t('update') : this.$t('create'))}]);

            if (this.idEdit) {
                this.getData();
            }

        },
    };
</script>


